import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/components/is-mobile-view';

const CardWrapper = styled.div`
  background: white;
  transition: all 0.1s;
  // padding: 20px;
  border-radius: 10px;
  box-shadow: ${props =>
    props.isSelected === true
      ? '2px 2px 20px 5px rgba(0, 0, 0, 0.15)'
      : '2px 2px 20px 5px rgba(0, 0, 0, 0.1)'};

  transform: ${props =>
    props.isSelected === true ? 'scale(1.025)' : 'scale(1)'};

  .header {
    position: relative;
    z-index: 2;
    padding-right:0;

    .image {
      width: 100%;
      height: 120px;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }
  }

  .content-col{
    padding-right:0;
  }
  .content {
    padding: 10px 5px;
    height:auto;

    // .title,
    // .city,
    .description {
      padding: 0 5px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .title,
    .city {
      // -webkit-line-clamp: 1;
      text-overflow: ellipsis;

      /* Needed to make it work */
      overflow: hidden;
      white-space: nowrap;
    }

    .title {
      font-size: 13px;
    }

    .description-box {
      padding-top: 15px;
      padding-bottom: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .description {
      -webkit-line-clamp: 2;
    }
  }

  .footer {
    padding: 0 5px 10px;
    display: flex;
    white-space: nowrap;

    .distance,
    .business-hours {
      display: flex;
      align-items: center;
      font-size:11px;

      .icon {
        margin-right: 4px;
        color: black;
        &.adn-clock {
          font-size: 20px;
        }
      }
    }

    .distance {
      margin-right: 10px;
    }

    .business-hours {
      color: limegreen;
      color: ${props => props.product.hours === 'open' && 'limegreen'};
      color: ${props => props.product.hours === 'closed' && 'indianred'};
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    margin: 20px 0;
    box-shadow: ${props =>
      props.isSelected === true
        ? '2px 2px 20px 5px rgba(0, 0, 0, 0.15)'
        : '2px 2px 20px 5px rgba(0, 0, 0, 0.1)'};
  }
`;

export default CardWrapper;
