import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import slugify from 'url-slug';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import Favorite from 'ToolboxComponents/commons/buttons/favorite/favorite';
import { CardWrapper } from 'ToolboxComponents/commons/cards/results-product-card/results-product-card.style';
import Text from 'ToolboxComponents/commons/texts/text/text';
import DistanceConverted from 'ToolboxComponents/utils/formats/distances/distance';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import { ThemeContext, LanguageContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import formatSchedule from 'ToolboxUtils/web/formats/format-schedule';


const ResultsProductCard = ({ cardId, className, handleHover, product, toggleFavoriteActive, isCluster, isEvent, isSelectedProduct, targetPage, fromPage, highlightText }) => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();

  const [scheduleStatus, setScheduleStatus] = useState('');
  const [schedulePath, setSchedulePath] = useState(null);
  const [scheduleHour, setScheduleHour] = useState({});
  const [scheduleDate, setScheduleDate] = useState({});


  useEffect(() => {
    const schedule = formatSchedule(
      isEvent, {
      startTime: product.startTime,
      endTime: product.endTime,
      language,
    });
    setScheduleStatus(schedule.status);
    setSchedulePath(schedule.message);
    setScheduleHour(schedule.hour);
    setScheduleDate(schedule.date);
    // eslint-disable-next-line
  }, [product]);

  

  const isBiennale = product.idEvenementGenerique === 3834 || product.isBiennaleEvent ===  true
  
  return (
    <CardWrapper id={cardId} className={`product-card ${className}`} highlight={highlightText !== null} theme={theme} onMouseEnter={() => handleHover()}>
      {highlightText !== null && <div className='highlight-block'>
        {highlightText}
      </div>} 
      <div className={`header  ${isBiennale ? 'macaron-biennale' : ''}`}>
        <Anchor code={targetPage} pageParams={`/${product.id}/${slugify(product.title, {lower: true})}`}  urlParams={{startTime: product.startTime, endTime: product.endTime}}>
          <ImageResize className='image' src={product.photo} alt={product.title} />
        </Anchor>
        {toggleFavoriteActive && <Favorite product={product} setFavorite={toggleFavoriteActive} fromPage={fromPage || 'listing'} />}
      </div>
      <Anchor code={targetPage} pageParams={`/${product.id}/${slugify(product.title, {lower: true})}`} urlParams={{startTime: product.startTime, endTime: product.endTime}}>
        <div className='content'>
          <div className='title'>{product.title}</div>
          {language === "fr" && product.secretCount > 0 && (
            <div className={`nb-secrets`}>
              {product.secretCount} secret
              {product.secretCount > 1 ? 's' : ''}
            </div>
          )}
          {product.teaser && (typeof product.teaser === 'string') &&
            <div className='description'>{product.teaser}</div>
          }
        </div>
        {isCluster ?
          <div className='footer'>
            <div className='distance'>
            <Text
              path={'generic.circuits.stops'}
              data={{ stopCount: product.stopCount }}
            />
            </div>
          </div>
        :
        <div className='footer'>
        <div className='distance'>
          <span className='adn adn-location-arrow icon' />
          <DistanceConverted distance={product.distance} />
        </div>
        {!(whiteLabel.config.isSelectedProductScheduleHidden && isSelectedProduct) &&
          <div className='business-hours'>
            <span className='adn adn-clock icon' />
            {schedulePath !== null && (
              <Text
                path={schedulePath}
                data={{ time: scheduleHour, date: scheduleDate }}
                className={`is-${scheduleStatus}`}
              />
            )}
          </div>
        }
      </div>
        }
        
      </Anchor>
    </CardWrapper>
  );
};

ResultsProductCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleHover: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  toggleFavoriteActive: PropTypes.func.isRequired,
};

export default ResultsProductCard;
