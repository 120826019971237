import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import { WhiteLabelContext, LanguageContext } from "ToolboxUtils/web/context/context";
import getText from 'ToolboxUtils/web/get-text';


import { NavBarWrapper } from 'ToolboxComponents/webapp-light/pages/account-view/components/account-nav-bar/account-nav-bar.styles';

const AccountNavBar = props => {
  const whiteLabel = useContext(WhiteLabelContext).state;
  const language = useContext(LanguageContext).state;
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [navBarTopPosition, setNavBarTopPosition] = useState(0);
  useEffect(() => {
      setTimeout(function(){
        const mainNav = document.getElementById('main-nav');
        if (mainNav) {
          setNavBarTopPosition(`${mainNav.offsetHeight}px`);
        }
      }, 100);
  }, []);

  const goToTop = () => {
    window.scrollTo(0,0);
  }
  return (
    <NavBarWrapper isMobile={isMobile} navBarTopPosition={navBarTopPosition} id='nav-bar' >
      <div className='nav-bar container'>
        <button onClick={() => (goToTop(), props.setTab('profile'))} className={props.tabSelected === 'profile' ? 'selected' : null}>{whiteLabel.textualContent.page.account.profileTab}</button>
        {language === 'fr' && <button onClick={() => (goToTop(), props.setTab('secrets'))} className={props.tabSelected === 'secrets' ? 'selected' : null}>
          {props.secretCount === 1 ? whiteLabel.textualContent.page.account.secretTab : whiteLabel.textualContent.page.account.secretsTab}
        </button>}
        <button onClick={() => (goToTop(), props.setTab('favorites'))} className={props.tabSelected === 'favorites' ? 'selected' : null}>
          {getText(whiteLabel.textualContent, 'page.account.favorites', {nbFavorites: props.nbFavorites, plural: props.nbFavorites > 1 ? 's' : ''})}
        </button>
      </div>
    </NavBarWrapper>
  );
}

AccountNavBar.propTypes = {
  tabSelected: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  secretCount: PropTypes.number.isRequired,
  nbFavorites: PropTypes.number.isRequired,
}

export default AccountNavBar;
