import styled from 'styled-components';
import getDevicePlatform from 'ToolboxUtils/web/get-device-platform';
import { isMobileBreakpoint } from 'ToolboxUtils/web/components/is-mobile-view';

const platform = getDevicePlatform();

const ResultsWrapper = styled.div`
  margin-top: ${props => props.hasFilters === false ? '' : (props.isMobile === true ? props.marginTopMobile : 0)};
  display: flex;
  margin-left: calc(-50vw + 50%);
  .results-container{
      width: 100%;
    .row-results-sidebar{
      display: flex;
      flex-direction:row-reverse;
      margin-top: ${props => (props.isMobile === true ? '' : (props.isSearch ? '110px' : '0px'))};
    }
  }
  .results-container{
    text-align:center;
    .map{
      text-align:center;
      color:white;
      background-color:${props => props.theme.color.secondaryColor};;
      border: 1px solid #DDDDDD;
      border-radius: 20px;
      padding:5px 15px;
      position: sticky;
      top: calc(100vh - 95px);
      left: 0;
      right: 0;
      z-index: 1;
      display: inline-block;
      span{
        margin-right:5px;
      }
    }
  }
  
  .row-sidebar {
    padding-left: 12px
    width: ${props => (props.isMobile === true ? '100%' : '632px')};
  }
  .desktop-map {
    flex-grow: 1;
    flex-shrink: 1;
    z-index: 0;
    position: sticky;
    top: ${props => (props.isSearch ? '190px' : '80px')};
    align-self: flex-start;
    height: ${props => props.height};
    &>div {
      width: 100%;
      height: 100%;
    }
  }

  .mobile-map {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    z-index: 2;

    .mobile-map--header {
      background: ${props => props.theme.color.primaryColor};
      height: 45px;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
    }

    .mobile-map--close {
      font-size: ${props => `${20*props.theme.font.primaryFont.scaling/100}px`};
      display: flex;
      margin-right: 5px;
    }

    .mobile-map--map {
      background: greenyellow;
      height: 100%;
      height: ${props => platform === 'ios' && 'calc(100% - 230px - 45px)'};
    }

    .mobile-map--slider {
      background: white;
      height: 230px;

      .swiper-slide {
        .header {
          .image {
            height: 100px;
          }
        }
      }
    }
  }
`;

const ResultsSidebarWrapper = styled.div`
  order: 1;
  height: 100%;
  z-index: 2;
  width: 100%;
  margin-top: ${props => (props.isMobile === true ? '10px' : (props.isSearch && props.pageVisible === 1 ? '40px' : '0px'))};
  &::-webkit-scrollbar {
    display: none;
  }
  

  .pages-scroll {
    align-items: center;
    border-radius: 6px;
    box-shadow: 2px 2px 20px 5px rgba(0,0,0,0.1);
    display: flex;
    float: right;
    justify-content: space-between;
    margin-left: 30px;
    padding: 10px 15px;
    position: relative;
    width: ${props => (props.isMobile === true ? '100%' : '360px')};
    margin-bottom: ${props => (props.isMobile === true ? '72px' : '')};
    .arrows {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100px;
      .icon {
        align-items: center;
        background: ${props => props.theme.color.primaryColor};
        border-radius: 15px;
        color: white;
        display: flex;
        font-size: 25px;
        font-weight: bold;
        height: 30px;
        justify-content: center;
        width: 30px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .list-cards {
    padding-top:30px;
    & .product-card {
      display: block;
      margin: 0px 20px 52px 0;
      box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.1);
      position: ${props => (props.isMobile === true ? 'static' : 'relative')};
      right: 0;
      width: ${props => (props.isMobile === true ? '100%' : '300px')};
      float: left;
      z-index: 1;
      /*transition: all .05s ease-in-out;*/
      & > div {
        margin: 0;
      }


      &:last-of-type {
        margin-bottom: 20px;
      }
    }
    & .product-card:hover {
      cursor:pointer;
      /*transform:scale(1.055);*/
    }


  }

  .category-name {
    background: ${props => props.theme.color.tertiaryColor};
    display: flex;
    flex-direction: column;
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    padding: 20px 15px 10px 20px;
    text-align: center;
  }

  .category-description {
    background: ${props => props.theme.color.tertiaryColor};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)};
    padding: 20px;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .list-cards{
      &.product-card {
        display: block;
        margin: 0px 20px 32px 0;
      }
    }
    .list-cards .product-card:last-of-type {
      margin-bottom: 50px;
    }
    .list-cards .product-card:nth-child(even) {
      margin-right: 0; !important
    }
    .map{
      display:none;
    }

    .list-cards {
      & .product-card {
        &.selected {
          /*transform:scale(1.055);*/
          background-color: #baf5c4;
        }
        &.selected .highlight-block {
          /*transform:scale(1.055);*/
          background-color: #baf5c4;
        }
      }
      & .product-card:hover .highlight-block{
        background: #baf5c4;
      }
    }
  }
`;

export default ResultsWrapper;
export { ResultsSidebarWrapper };
