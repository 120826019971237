import React, { useState, useEffect, useContext } from 'react';
import { WhiteLabelContext, LanguageContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import Text from 'ToolboxComponents/commons/texts/text/text';
import ModalEditField from 'ToolboxComponents/webapp/modals/modal-edit-field/modal-edit-field';
import SliderProfiles from "ToolboxComponents/commons/sliders/slider-profiles/slider-profiles";
import OptionGroup from "ToolboxComponents/commons/buttons/option-group/option-group";
import SecretInsert from 'ToolboxComponents/webapp-light/pages/account-view/components/secret-insert/secret-insert';
import UploadPicture from 'ToolboxComponents/webapp-light/pages/account-view/components/upload-picture/upload-picture';
import { getNbSlidesAndWidth } from 'ToolboxUtils/web/slider-profiles/getNbSlidesAndWidth';
import { AccountProfileTabWrapper } from 'ToolboxComponents/webapp-light/pages/account-view/components/account-profile-tab/account-profile-tab.styles';

const AccountProfileTab = props => {
  const { account, theme, secretCount, sendActivationEmail, fakeBannerHeight } = props;

  const [language] = useContext(LanguageContext).usePath();
  const [textualContent] = useContext(WhiteLabelContext).usePath('textualContent');

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [modalFirstNameOpened, setModalFirstNameOpen] = useState(false);
  const [modalEmailOpened, setModalEmailOpen] = useState(false);
  const [modalPasswordOpened, setModalPasswordOpen] = useState(false);
  const [modalBiographyOpened, setModalBiographyOpen] = useState(false);

  const [transport, setTransport] = useState();
  useEffect(() => {
    if (transport) {
      props.setTransport(transport);
    }
  // eslint-disable-next-line
  }, [transport]);

  const [profile, setProfile] = useState();
  useEffect(() => {
    if (profile) {
      props.setProfile(profile);
    }
  // eslint-disable-next-line
  }, [profile]);

  const [newProfileSlideWidth, setProfileSlideWidth] = useState(null);
  const [nbProfileSlides, setProfileSlides] = useState(null);
  useEffect(() => {
    setTimeout(function(){
      let data = getNbSlidesAndWidth(props.profiles.length);
      setProfileSlides(data.nbProfileSlides);
      setProfileSlideWidth(data.newProfileSlideWidth);
    }, 100);
  // eslint-disable-next-line
  }, []);

  const [photoValue, setPhotoValue] = useState('');

  const confirmAccountDeletion = () => {
    if (window.confirm(textualContent.page.account.confirmDeletion)) {
      props.deleteAccount();
    }
  }
  return (
    <AccountProfileTabWrapper theme={theme} isMobile={isMobile} fakeBannerHeight={fakeBannerHeight}>
      <div className='profile-container'>
        {!account.isActivated &&
          <div className='validation'>
            {textualContent.page.account.notActivated}
            <button type='button' className='email' onClick={() => sendActivationEmail()}>
              {textualContent.page.account.reSend}
            </button>
          </div>
        }
        <button type='button' className='edit-profile-picture'>
          <UploadPicture
            account={account}
            photoValue={photoValue}
            handlePhoto={path => props.setProfilePicturePath(path)}
          />
        </button>
        {isMobile &&
          <button onClick={() => props.logOut()} type='button' className='logout'>
            <i className='icon adn adn-power' />
            {textualContent.page.account.signOut}
          </button>
        }
        {isMobile && language === 'fr' &&
          <SecretInsert secretCount={secretCount} firstName={account.firstName} theme={theme} openAddSecretModal={(flag) => props.openAddSecretModal(flag)}  />
        }
        <div className='form'>
          <div className='field'>
            <label htmlFor='firstName'>
              <Text path={`page.account.firstName`} />
            </label>
            <div className='input-line' onClick={() => setModalFirstNameOpen(true)}>
              <input type='text' name='firstName' id='firstName' value={account.firstName} disabled/>
              <button type='button' className='edit-field-button'>
                <i className='icon adn adn-pencil' />
              </button>
            </div>
          </div>
          <div className='field'>
            <label htmlFor='email'>
              <Text path={`page.account.email`} />
            </label>
            <div className='input-line' onClick={() => setModalEmailOpen(true)}>
              <input type='email' name='email' id='email' value={account.email} disabled />
              <button type='button' className='edit-field-button'>
                <i className='icon adn adn-pencil' />
              </button>
            </div>
          </div>
          <div className='field'>
            <label htmlFor='password'>
              <Text path={`page.account.password`} />
            </label>
            <div className='input-line' onClick={() => setModalPasswordOpen(true)}>
              <input type='password' name='password' id='password' defaultValue='password' disabled />
              <button type='button' className='edit-field-button'>
                <i className='icon adn adn-pencil' />
              </button>
            </div>
          </div>
          <div className='field'>
            <label htmlFor='biography'>
              <Text path={`page.account.biography`} />
            </label>
            <div className='input-line' onClick={() => setModalBiographyOpen(true)}>
              <textarea name='biography' id='biography' value={account.biography && account.biography.fr ? account.biography.fr : ''} disabled />
              <button type='button' className='edit-field-button'>
                <i className='icon adn adn-pencil' />
              </button>
            </div>
          </div>
        </div>
        <div className='account-delete'>
          <button type='button' className='delete' onClick={() => confirmAccountDeletion()}>{textualContent.page.account.delete}</button>
        </div>
      </div>
      {!isMobile && language === 'fr' &&
        <SecretInsert secretCount={secretCount} firstName={account.firstName} theme={theme} openAddSecretModal={(flag) => props.openAddSecretModal(flag)} />
      }
      {modalFirstNameOpened &&
        <ModalEditField
          title={textualContent.page.account.modifyFirstName}
          defaultValue={account.firstName}
          name='firstName'
          type='text'
          onSubmit={(firstName) => props.editFirstName(firstName)}
          closeModal={() => setModalFirstNameOpen(false)}
        />
      }
      {modalEmailOpened &&
        <ModalEditField
          title={textualContent.page.account.modifyMail}
          name='email'
          type='email'
          confirmation={true}
          onSubmit={(email) => props.editEmail(email)}
          closeModal={() => setModalEmailOpen(false)}
        />
      }
      {modalPasswordOpened &&
        <ModalEditField
          title={textualContent.page.account.modifyPassword}
          name='password'
          type='password'
          confirmation={true}
          actualConfirmation={true}
          onSubmit={(passwords) => props.editPassword(passwords)}
          closeModal={() => setModalPasswordOpen(false)}
        />
      }
      {modalBiographyOpened &&
        <ModalEditField
          title={textualContent.page.account.modifyBiography}
          defaultValue={account.biography}
          name='biography'
          type='textarea'
          onSubmit={(biography) => props.editBiography(biography)}
          closeModal={() => setModalBiographyOpen(false)}
        />
      }
    </AccountProfileTabWrapper>
  );
};

AccountProfileTab.propTypes = {
  account: PropTypes.object.isRequired,
  secretCount: PropTypes.number.isRequired,
  transports: PropTypes.array.isRequired,
  profiles: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  editFirstName: PropTypes.func.isRequired,
  editEmail: PropTypes.func.isRequired,
  editPassword: PropTypes.func.isRequired,
  editBiography: PropTypes.func.isRequired,
  setTransport: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  openAddSecretModal: PropTypes.func.isRequired,
  setProfilePicturePath: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  sendActivationEmail: PropTypes.func.isRequired,
}

export default AccountProfileTab;
