import styled from 'styled-components';


export const BandeauFiltresWrapper = styled.div`
  background: white;
  left: 0;
  z-index:${props => props.isMobile === true ? '1' : '20'};
  width:100%;
  height:58px;
  box-shadow: 0px 6px 10px #00000029;
  position:${props => props.isMobile === true ? 'sticky' : 'fixed'};
  top: ${props => props.isMobile === true ? '60px' : ''};
  .boutons-filtres-row{
    justify-content:center;
    flex-wrap: nowrap;
    font-size:15px;
  }
  .is-disabled, button[disabled]{
    pointer-events: none;
    opacity: 0.8;
  }
  .filters{
    justify-content:center;
  }
  .mobile{
    margin: 4px 4px;
    padding: 4px;
    overflow: auto;
    box-shadow: 5px 4px 26px #00000029;
    white-space: nowrap;
    display:inline-flex;
    position:relative;
    .boutons-filtres-row{
      padding-left:15px;
    }
    .autocomplete-location,.autocomplete-profiles{
      margin-right:12px;
    }
    
  }
  
  .desktop{
    display:none;
  }
  .modal-container{
    background: rgba(40, 40, 40, .9);
    display:flex;
    justify-content:center;
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:5;
    .modal-filter{
      display: block;
      padding-top:16px;
      .modal-header{
        text-align: center;
        strong{
          color:black;
        }
      }
      background: white;
      position: fixed;
      top: 200px;
      z-index: 300;
      outline: 0;
      border-radius: 16px;
      width:360px;
      height:auto;
    &.modal-location{
      height:360px;
      text-align:center;
      >div{
        margin-bottom:15px;
      }
      .text-modal-location{
        color: ${props => { return props.theme.color.primaryColor }};
      }
      .bouton-plus-proche{
        background-color:  ${props => { return props.theme.color.primaryColor }};
        border-radius: 40px;
        margin: 15px 0;
        color:white;
        width:265px;
        height:39px;
        border:none;
        display:inline-flex;
        justify-content:center;
        align-items:center;
        font-size:15px;
        i{
          font-size:32px;
        }
      }
    }
    }
  }
  
  .bouton{
    background: white;
    color:black;
    border: 1px dashed #707070;
    box-shadow: 2px 5px 8px #00000029;
    border-radius: 20px;
    margin-right: 12px;
    max-height: 46px;
    height:46px;
    width:290px;
    max-width:290px;
    text-align:left;
    .icon{
      width: 32px;
      font-size:24px;
      padding-left:8px;
      margin-right: 8px;
    }
    &:hover{
      background-color: ${props => { return props.theme.color.primaryColor }};
      border:none;
    }
    &.active{
      background-color: ${props => { return props.theme.color.primaryColor }};
      border:none;
    }
  }
  .bouton-envies, .bouton-moment,.bouton-location,.bouton-secret-only,.bouton-plus-filtres{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .bouton-envies{
    width:240px;
    max-width:240px;
  }
  .bouton-moment{
    width:200px;
    max-width:200px;
  }
  .bouton-location{
    width:209px;
    max-width:209px;
  }
  .bouton-plus-filtres{
    width:179px;
    max-width:179px;
  }

  .autocomplete-location{
    margin-right:12px;
  }
  .bouton-type-profil{
    padding-right:28px;
  }
  .option-groups {
    margin-top: ${props => (props.isMobile === true ? '30px' : '50px')};
    display: grid;
    grid-template-columns: ${props => (props.isMobile === true ? '1fr' : '50% 50%')};
  }
  .option-group {
    margin-bottom: 40px;
    margin-right: 50px;
  }
  .option-group.transports, .option-group.budget {
    margin-left: ${props => (props.isMobile === true ? '' : '50px')};
  }
  .page-title {
    margin-bottom: ${props => (props.isMobile === true ? '1rem' : '3rem')};
    font-size: ${props => (props.isMobile === true ? `${21*props.theme.font.primaryFont.scaling/100}px` : '')};
  }
  .slider-title {
    margin-top: 30px;
  }
  .title {
    margin-bottom: 20px;
  }
  .highlight {
    color: ${props => { return props.theme.color.primaryColor }};
  }
  strong {
    color: ${props => { return props.theme.color.primaryColor }};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  }
  h1 {
    font-size: ${props => `${40*props.theme.font.primaryFont.scaling/100}px`};
  }
  h3 {
    font-size: ${props => `${20*props.theme.font.primaryFont.scaling/100}px`};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  }
  #toastNoDesire,#toastNoProducts {
    visibility: hidden;
    position: ${props => { return props.toastCssPosition }};
    bottom: ${props => { return props.toastBottomPosition }};
    left: 50%;
    transform: translateX(-50%);
    &.show {
      visibility: visible;
      animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }
  }
  @media (min-width: 767px) {
    padding: 20px 0px 14px;
    width:calc(100vw -20px);
    height:86px;
    .slider-profiles {
      width: 94vw;
    }
    .mobile{
      display:none;
    }
    .desktop{
      display:block;
      background:white;
      .boutons-filtres-row{
        .bouton{
          margin-bottom:20px;
        }  
      }
      .boutons-filtres {
        box-shadow: 0px 6px 10px #00000029;
      }
    }
  }

  @keyframes fadein {
    from {
      bottom: ${props => { return props.pageHeight }};
      opacity: 0;
    }
    to {
      bottom: ${props => { return props.toastBottomPosition }};
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      bottom: ${props => { return props.toastBottomPosition }};
      opacity: 1;
    }
    to {
      bottom: ${props => { return props.pageHeight }};
      opacity: 0;
    }
  }
`;
