import React, { useContext }  from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ResultsProductCard from 'ToolboxComponents/commons/cards/results-product-card/results-product-card';
import { LanguageContext } from "ToolboxUtils/web/context/context";

const ListCardsWrapper = styled.div``;

const getHighlightText = (index, hasOneHighlightProductOnly) => {
  const [language] = useContext(LanguageContext).usePath();
  if (index === 0) {
    return <><i className="icon adn adn-map-marker"></i>{{
      fr: <span>Le plus proche</span>,
      en: <span>The closest</span>
    }[language]}</>;
  } else if (index === 1 && !hasOneHighlightProductOnly && language === "fr") {
    return <><i className="icon adn adn-heart"></i>Le plus chuchoté</>;
  } else {
    return null;
  }
};

const ListCards = ({ activeProducts, isSelectedProduct, handleHover, onClickListCard, selectedSlide, toggleFavoriteActive, pageVisible, isCluster, isEvent, targetPage, hasOneHighlightProductOnly }) => {
  return (
    <ListCardsWrapper>
      <div className="list-cards">
        {activeProducts.map((product, index) => (
          <ResultsProductCard
            key={index}
            cardId={`slide-product--${index+(pageVisible-1)*10}`}
            handleHover={() => {handleHover(index)}}
            product={product}
            toggleFavoriteActive={toggleFavoriteActive && (active => toggleFavoriteActive({active: active, productId: product.id}))}
            className={`${index === selectedSlide-(pageVisible-1)*10 ? 'selected' : ''} `}
            onClick={() => onClickListCard(index)}
            isCluster={isCluster}
            isEvent={isEvent}
            targetPage={targetPage}
            isSelectedProduct={isSelectedProduct}
            fromPage={'listing'}
            highlightText={getHighlightText(index+(pageVisible-1)*10, hasOneHighlightProductOnly)}
          />
        ))}
      </div>
    </ListCardsWrapper>
  );
};

ListCards.propTypes = {
  activeProducts: PropTypes.array,
  handleHover: PropTypes.func,
  toggleFavoriteActive: PropTypes.func,
  selectedSlide: PropTypes.number,
  onClickListCard: PropTypes.func,
  pageVisible: PropTypes.number.isRequired,
};

export default ListCards;
