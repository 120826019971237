import formatHour from 'ToolboxUtils/web/formats/format-hour';

export default function formatSchedule(schedule, moment) {
  if (schedule === undefined || schedule === null) {
    return { status: 'off', message: 'generic.schedule.closed' };
  }
  const { startTime, endTime, language } = schedule;
  if (startTime === undefined || startTime === null) {
    return { status: 'off', message: 'generic.schedule.closed' };
  }
  const nowDate = new Date();
  const startDate = new Date(startTime * 1000);
  const endDate = new Date(endTime * 1000);
  const diffDays = computeDaysBetween(nowDate, startDate);

  if (nowDate > endDate) {
    return { status: 'off', message: 'generic.schedule.closed' };
  }
  if (moment) {
    const diffMoment = computeDaysBetween(
      nowDate,
      new Date(moment.startMoment * 1000),
    );
    // if it's a large time frame, it's probably open the whole day, whole week, etc. so no need to display the hour
    if (endTime - startTime > 3600 * 23) {
      if (startDate < nowDate) {
        if (diffMoment === 0) {
          return { status: 'on', message: 'generic.schedule.open' };
        } else if (diffMoment === 1) {
          return { status: 'ok', message: 'generic.schedule.openTomorrow' };
        } else {
          return { status: 'off', message: 'generic.schedule.closed' };
        }
      } else {
        if (diffMoment === 0) {
          return { status: 'off', message: 'generic.schedule.openLater' };
        } else if (diffMoment === 1) {
          return { status: 'off', message: 'generic.schedule.openTomorrow' };
        } else {
          return { status: 'off', message: 'generic.schedule.closed' };
        }
      }
    } else {
      const startHour = formatHour({ date: startDate, language: language });
      const endHour = formatHour({ date: endDate, language: language });
      if (startDate < nowDate) {
        if (diffMoment === 0) {
          return {
            status: 'on',
            message: 'generic.schedule.openUntil',
            hour: endHour
          };
        } else if (diffMoment === 1) {
          return {
            status: 'ok',
            message: 'generic.schedule.openTomorrowAt',
            hour: startHour
          };
        } else {
          return { status: 'off', message: 'generic.schedule.closed' };
        }
      } else {
        if (diffMoment === 0) {
          return {
            status: 'ok',
            message: 'generic.schedule.openAt',
            hour: startHour,
          };
        } else if (diffMoment === 1) {
          return {
            status: 'ok',
            message: 'generic.schedule.openTomorrowAt',
            hour: startHour,
          };
        } else {
          return { status: 'off', message: 'generic.schedule.closed' };
        }
      }
    }
  } else { // no moment
    if (endTime - startTime > 3600 * 23) {
      if (startDate < nowDate) {
        return { status: 'on', message: 'generic.schedule.open' };
      } else {
        if (diffDays === 0) {
          return { status: 'off', message: 'generic.schedule.openLater' };
        } else if (diffDays === 1) {
          return { status: 'off', message: 'generic.schedule.openTomorrow' };
        } else {
          return { status: 'off', message: 'generic.schedule.closed' };
        }
      }
    } else {
      const startHour = formatHour({ date: startDate, language: language });
      const endHour = formatHour({ date: endDate, language: language });
      if (startDate < nowDate) {
        return {
          status: 'on',
          message: 'generic.schedule.openUntil',
          hour: endHour,
        };
      } else {
        if (diffDays === 0) {
          if (moment === null) {
            return {
              status: 'off',
              message: 'generic.schedule.openAt',
              hour: startHour,
            };
          } else {
            return {
              status: 'ok',
              message: 'generic.schedule.openAt',
              hour: startHour,
            };
          }
        } else if (diffDays === 1) {
          return {
            status: 'off',
            message: 'generic.schedule.openTomorrowAt',
            hour: startHour,
          };
        } else {
          return { status: 'off', message: 'generic.schedule.closed' };
        }
      }
    }
  }
}

function computeDaysBetween(date1, date2) {
  const ONE_DAY = 1000 * 60 * 60 * 24;

  const tempDate1 = new Date(date1.getTime());
  const tempDate2 = new Date(date2.getTime());

  tempDate1.setHours(0, 0, 0, 0);
  tempDate2.setHours(0, 0, 0, 0);

  return Math.round(
    Math.abs((tempDate1.getTime() - tempDate2.getTime()) / ONE_DAY),
  );
}
