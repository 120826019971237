import React, { useState, useEffect, useContext} from 'react';
import { useHistory } from 'react-router-dom'
import { Helmet } from "react-helmet";
import jwt from 'jwt-decode';
import { WebappContext, AccountContext, LanguageContext, WhiteLabelContext, GeolocationContext } from "ToolboxUtils/web/context/context";
import AccountView from 'ToolboxComponents/webapp-light/pages/account-view/account-view';
import Toast from 'ToolboxComponents/commons/toast/toast';
import Loader from 'ToolboxComponents/commons/loader/loader';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import getPath from 'Utils/web/get-path';

import { transports } from "ToolboxParams/transports";
import { PAGE_LOAD_WAITING } from 'Params/globals';

let jwtdecode = null;

const Account = props => {
  const history = useHistory();

  const [account, setAccount] = useContext(AccountContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [geolocation] = useContext(GeolocationContext).usePath();

  if (!localStorage.token) {
    history.push(getPath('/', whiteLabel, language));
  }

  const [secretsList, setSecretsList] = useState([]);
  const [favoritesList, setFavoritesList] = useState([]);
  const [isAccountDeleted, setAccountDeleted] = useState(false);
  const [isConfirmationMessageShowed, showConfirmationMessage] = useState(false);
  const [isSecretSaved, setSecretSaved] = useState(false);
  const [errorOnSecretSave, setErrorOnSecretSave] = useState(false);
  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!(profiles && profiles.isComplete && account)) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  useEffect(() => {
    if (isAccountDeleted) {
      if (account.isGoogle && window.gapi) {
        const auth2 = window.gapi.auth2.getAuthInstance();
        auth2.disconnect();
      }
      logOut();
    }
  }, [isAccountDeleted]);

  useEffect(() => {
    if (!account || !account.id) {
      return;
    }

    async function getSecrets() {
      try {
        const secrets = await apiChuchoteurs.get(`/secrets`, {
          params: {accountId: account.id, whiteLabelId: whiteLabel.id, language: language, filter: 'account-private'},
          token: localStorage.token
        });
        setSecretsList(secrets)
      } catch (err) {
        apiChuchoteurs.post(`/logs`, {
          body: {error: {...err, type: 'front'}}
        })
      }
    };
    getSecrets();
    if (account.favorites.length > 0) {
      getFavorites(account.favorites);
    }

  }, [account && account.id]);

  useEffect(() => {
    if (isConfirmationMessageShowed) {
      setTimeout(() => showConfirmationMessage(false), 5000);
    }
  }, [isConfirmationMessageShowed]);

  useEffect(() => {
    if (errorOnSecretSave) {
      setTimeout(function(){
        setErrorOnSecretSave(false);
      }, 6000);
    }
  }, [errorOnSecretSave]);

  const logOut = () => {
    localStorage.clear();
    setAccount(undefined);
    history.push(getPath('/', whiteLabel, language));
  }

  const addAccountToContext = token => {
    const decode = () => {
      const data = jwtdecode(token);
      console.log(data);
      localStorage.setItem('token', token);
      const {favorites} = account;
      setAccount({...data, favorites: favorites});
    };
    if (jwtdecode === null) {
      import(/* webpackChunkName: "account" */ 'jwt-decode').then(x => {
        jwtdecode = x.default;
        decode();
      });
    } else {
      decode();
    }
  }

  const updateSecretsList = newSecret => {
    let newSecretsList = [];
    for (let secret of secretsList) {
      if (newSecret.update && secret.id === newSecret.secret.id) {
        newSecretsList.push(newSecret.secret);
      } else {
        newSecretsList.push(secret);
      }
    }
    if (!newSecret.update) {
      newSecretsList.push(newSecret.secret);
    }
    setSecretsList(newSecretsList);
  }

  const profiles = useApiChuchoteurs.get(`/profiles`, {
    params: {whiteLabelId: whiteLabel.id, language: language},
  });

  const deleteSecretFromList = id => {
    const newSecretsList = secretsList.filter(secret => secret.id !== id);
    setSecretsList(newSecretsList);
  }

  const updateFav = id => {
    if (account.favorites.includes(id)) {
      const favorites = account.favorites.filter(favorite => favorite !== id);
      setAccount('favorites', favorites);
      if (favorites.length > 0) {
        getFavorites(favorites);
      } else {
        setFavoritesList([]);
      }
    } else {
      const favorites = account.favorites.concat([id]);
      setAccount('favorites', favorites);
      getFavorites(favorites);
    }
  }

  useIsContentLoaded(profiles && profiles.isComplete && account);
  return (
    <>
      {profiles && profiles.isComplete && account ?
        <AccountView
          secrets={secretsList}
          favorites={[]}
          transports={transports[language]}
          profiles={profiles.data}
          isSecretSaved={isSecretSaved}
          errorOnSecretSave={errorOnSecretSave}
          setSecretSaved={setSecretSaved}
          onSecretDelete={async (id) => {
            try {
              const sendDeleteSecret = await apiChuchoteurs.delete(`/secrets/${id}`, {
                body: {filter: 'user'},
                token: localStorage.token
              })
              deleteSecretFromList(id);
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          editFirstName={async (firstName) => {
            try {
              const token = await apiChuchoteurs.put(`/accounts/${account.id}`, {
                body: {firstName: firstName, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              addAccountToContext(token);
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          editEmail={async (email) => {
            try {
              const token = await apiChuchoteurs.put(`/accounts/${account.id}`, {
                body: {email: email, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              addAccountToContext(token);
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          editPassword={async (passwords) => {
            try {
              const token = await apiChuchoteurs.put(`/accounts/${account.id}`, {
                body: {passwords: passwords, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              addAccountToContext(token);
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          editBiography={async (biography) => {
            try {
              const token = await apiChuchoteurs.put(`/accounts/${account.id}`, {
                body: {biography: biography, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              addAccountToContext(token);
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          setTransport={async (id) => {
            try {
              const token = await apiChuchoteurs.put(`/accounts/${account.id}`, {
                body: {transportId: id, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              addAccountToContext(token);
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          setProfile={async (id) => {
            try {
              const token = await apiChuchoteurs.put(`/accounts/${account.id}`, {
                body: {profileId: id, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              addAccountToContext(token);
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          updateSecret={async (secret) => {
            try {
              const updatedSecret = await apiChuchoteurs.put(`/secrets/${secret.id}`, {
                body: {...secret, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              setSecretSaved(true);
              updateSecretsList({secret: updatedSecret, update: true});
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          createSecret={async (secret) => {
            try {
              const createdSecret = await apiChuchoteurs.post(`/secrets/`, {
                body: {accountId: account.id, ...secret, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              setSecretSaved(true);
              updateSecretsList({secret: createdSecret, update: false});
            } catch (err) {
              setErrorOnSecretSave(true);
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          setProfilePicturePath={async (path) => {
            try {
              const token = await apiChuchoteurs.put(`/accounts/${account.id}`, {
                body: {photo: path, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              addAccountToContext(token);
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          logOut={() => logOut()}
          addFavorite={async (id) => {
            try {
              const favorite = await apiChuchoteurs.post(`/accounts/${account.id}/products/${id}/favorites`, {
                token: localStorage.token
              })
              updateFav(id);
            } catch(err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          deleteFavorite={async (id) => {
            try {
              const favorite = await apiChuchoteurs.delete(`/accounts/${account.id}/products/${id}/favorites`, {
                token: localStorage.token
              })
              updateFav(id);
            } catch(err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          deleteAccount={async (id) => {
            try {
              await apiChuchoteurs.delete(`/accounts/${account.id}`, {
                body: { filter: 'user'},
                token: localStorage.token
              })
              setAccountDeleted(true);
            } catch(err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          sendActivationEmail={async () => {
            try {
              await apiChuchoteurs.post(`/accounts/active`, {
                token: localStorage.token
              })
              showConfirmationMessage(true);
            } catch(err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
        />
        : isLoaderDisplayed
          ? <Loader />
          : <></>
      }
      <Toast
        icon={'check'}
        background={'#2ecc40'}
        color={'#ffffff'}
        text={whiteLabel.textualContent.page.account.emailSent}
        visible={isConfirmationMessageShowed}
      />
    </>
  );
}

export default Account;
