export default function imageWidthCalculate(imageWidth) {
  if (imageWidth === 'force-large') {
    return 1200;
  }
  
  const windowWidth = window.innerWidth;
  let width = 480;

  //mobiles: always serve the small size
  if (windowWidth < 480) {
    return width;
  }

  //desktop: serve small size unless specified
  if (imageWidth === 'large') {
    width = 1200;
  }
  return width;
}
