import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, GeolocationContext, WhiteLabelContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Map from 'ToolboxComponents/commons/map/map';
import Lightbox from 'ToolboxComponents/webapp/modals/lightbox/lightbox';
import PropTypes from 'prop-types';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import DefaultSecretPhoto from "ToolboxAssets/img/default-secret-photo.png";
import { UserSecretWrapper } from './user-secrets-product-card.styles';

const UserSecretsSlide = ({ secret, nbSecrets, onDelete, editSecret }) => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const [geolocation] = useContext(GeolocationContext).usePath();
  const [textualContent] = useContext(WhiteLabelContext).usePath('textualContent');

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);

  const deleteSecret = id => {
    if (
      window.confirm(
        textualContent.page.account.deleteSecretConfirm
      )
    ) {
      onDelete(id);
    }
  };

  const options = { day: '2-digit', year: 'numeric', month: 'long' };
  return (
    <UserSecretWrapper theme={theme} isMobile={isMobile} nbSecrets={nbSecrets}>
      <p className="whisper">{`${textualContent.page.account.whisperedOnChuchoté} ${new Date(
        secret.createdAt * 1000,
      ).toLocaleDateString(language, options)}`}</p>
      <div className="secret-container">
        <div className="img-container">
          {secret.photo ?
            <ImageResize className="secret-photo" src={secret.photo} />
            : <img className="secret-photo" src={DefaultSecretPhoto} />
          }
          {secret.photo &&
            <button onClick={() => setIsLightboxVisible(true)}>
              <i className="icon adn adn-magnify-plus-outline" />
            </button>
          }
        </div>
        <h3 className="secret-title">{secret.title}</h3>
        <div className="description">
          <div className="description-fade-out fade-out-top"></div>
          <p className="description-text">{secret.description}</p>
          <div className="description-fade-out fade-out-bottom"></div>
        </div>
        {secret.productId ? (
          <div className="product-container">
            <ImageResize src={secret.productPhoto} className="product-photo" />
            <div className="product-texts">
              <h5>{secret.productTitle}</h5>
              <p>à {secret.productCity}</p>
            </div>
          </div>
        ) : (
          <div className="location-container">
            <Map
              className="location-map"
              userLocation={geolocation && geolocation.coordinates}
              style={{ zIndex: 0 }}
            />
            <div className="location-texts">
              <p>à {secret.location}</p>
            </div>
          </div>
        )}
      </div>
      <div className="status">
        {secret.status === 'accepted' && (
          <p className="accepted">{textualContent.page.account.accepted}</p>
        )}
        {secret.status === 'pending' && (
          <p className="pending">{textualContent.page.account.pending}</p>
        )}
        {secret.status === 'refused' && (
          <React.Fragment>
            <p className="refused">
              {textualContent.page.account.refused}
              <a
                data-toggle="collapse"
                href="#refusal"
                role="button"
                aria-expanded="false"
                aria-controls="refusal"
                style={{
                  color: 'black',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  marginLeft: '10px',
                }}
                className="reason"
              >
                {textualContent.page.account.seeWhy}
              </a>
            </p>
            <div id="refusal" className="collapse">
              <div>{secret.refusalText}</div>
            </div>
          </React.Fragment>
        )}
        <div className="edition">
          <button onClick={() => editSecret(secret)}>{textualContent.page.account.editSecret}</button>
          <button onClick={() => deleteSecret(secret.id)}>{textualContent.page.account.deleteSecret}</button>
        </div>
      </div>
      {isLightboxVisible === true && (
        <Lightbox
          images={[secret.photo]}
          setSelectedSlide={id => setSliderIndex(id)}
          selectedSlide={sliderIndex}
          setLightboxVisible={flag => setIsLightboxVisible(flag)}
        />
      )}
    </UserSecretWrapper>
  );
};

UserSecretsSlide.propTypes = {
  secret: PropTypes.object.isRequired,
  nbSecrets: PropTypes.number,
  editSecret: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UserSecretsSlide;
