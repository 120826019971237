import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { SpecialHeaderWrapper } from 'ToolboxComponents/webapp/pages/results-view/components/special-header/special-header.styles';

const SpecialHeader = ({ isMobile, handleShowMap, title, teaser, highlight, pageVisible }) => {
  const theme = useContext(ThemeContext).state;
  return (
    <SpecialHeaderWrapper theme={theme} pageVisible={pageVisible}>
      <h1 className='title highlight'>
        {title}
      </h1>
      <div className='text'>
        <p>
          {teaser}
          <br />
          <span className='highlight'>{highlight}</span>
        </p>
      </div>
    </SpecialHeaderWrapper>
  );
};

SpecialHeader.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  handleShowMap: PropTypes.func.isRequired,
  title: PropTypes.string,
  teaser: PropTypes.string,
  highlight: PropTypes.string,
};

export default SpecialHeader;
